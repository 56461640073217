import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

interface WelcomeModaloProps {
  info: any;
  onClose: () => void;
}

const WelcomeModalo: React.FC<WelcomeModaloProps> = ({ info, onClose }) => {
  const { t } = useTranslation();
  const [username, setUsername] = useState('');
  const [teamName, setTeamName] = useState('');
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);
  const [isTeamNameAvailable, setIsTeamNameAvailable] = useState(true);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isAgeCertified, setIsAgeCertified] = useState(false);

  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`)
    ? `https://${stagingKey}.peaxel.me`
    : 'https://peaxel.me';

  // Validate username availability
  const checkUsernameAvailability = async (value: string) => {
    if (value.length < 6) {
      setIsUsernameAvailable(false);
      return;
    }

    try {
      const response = await axios.get(`${linkUrl}/wp-json/custom/v1/check_username?username=${value}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setIsUsernameAvailable(response.data === 'no'); // "no" means username is available
    } catch (error) {
      console.error('Error checking username:', error);
    }
  };

  const isUsernameValid = (value: string) => /^[a-zA-Z0-9@$ _-]+$/.test(value);

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setUsername(newValue);

    if (newValue.length >= 6 && newValue.length <= 30 && isUsernameValid(newValue) && !/\s/.test(newValue)) {
      checkUsernameAvailability(newValue);
    } else {
      setIsUsernameAvailable(false);
    }
  };

  const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(e.target.checked);
  };

  const handleAgeCertificationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgeCertified(e.target.checked);
  };

  // Validate team name availability
  const checkTeamNameAvailability = async (value: string) => {
    if (value.length < 3 || value.length > 30 || !/^[a-zA-Z0-9]+$/.test(value)) {
      setIsTeamNameAvailable(false);
      return;
    }

    try {
      const response = await axios.get(`${linkUrl}/wp-json/custom/v1/check_teamname?teamname=${value}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setIsTeamNameAvailable(response.data.teamname_available); // "true" means team name is available
    } catch (error) {
      console.error('Error checking team name:', error);
    }
  };

  const handleTeamNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setTeamName(newValue);

    if (newValue.length >= 3 && newValue.length <= 30 && /^[a-zA-Z0-9]+$/.test(newValue)) {
      checkTeamNameAvailability(newValue);
    } else {
      setIsTeamNameAvailable(false);
    }
  };

  // Register new user with the provided information
  const handleSignUp = async () => {
    if (isUsernameAvailable && isTeamNameAvailable && isUsernameValid(username) && termsAccepted && isAgeCertified && teamName.trim()) {
      try {
        const currency = localStorage.getItem('pxlCurrency');
        const language = localStorage.getItem('pxlLanguage');
        const userCreationData = {
          username,
          email: info.email,
          wallet: info.address,
          network: info.ethNetwork,
          wallet_preference: info.providerPreference,
          wallet_starkey: info.starkPublicKey,
          peaxel_currency: currency,
          teamName,// Add teamName to the payload
          peaxel_language: language,
        };

        const response = await axios.post(`${linkUrl}/wp-json/custom/v1/create_user_pxl`, userCreationData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.data.success) {
          localStorage.setItem('walletAddress', info.address);
          localStorage.setItem('walletEmail', info.email);
          localStorage.setItem('pxlPage', 'dashboard');
          localStorage.setItem('pxlSection', 'insights');
          localStorage.setItem('walletNetwork', info.ethNetwork);
          localStorage.setItem('walletStark', info.starkPublicKey);
          localStorage.setItem('pxlLanguage', language || 'en');
          localStorage.setItem('pxlCurrency', currency || 'USD');
          window.location.href = `${linkUrl}/game/`;
        } else {
          console.error('Failed to create user');
          setLocalStorageDefaults();
          window.location.href = `${linkUrl}/game/`;
        }
      } catch (error) {
        console.error('Error creating user:', error);
        setLocalStorageDefaults();
      }
    }
  };

  // Set default values in case of error or failure
  const setLocalStorageDefaults = () => {
    localStorage.setItem('walletAddress', '0xf9e2ebb0aa6723e5ff9d7915489fe2188e0b3c8a');
    localStorage.setItem('walletEmail', '');
    localStorage.setItem('pxlPage', 'dashboard');
    localStorage.setItem('pxlSection', 'insights');
    localStorage.setItem('walletNetwork', '');
    localStorage.setItem('walletStark', '');
  };

  return (
    <div className="aroundWelcomeModalo">
      <button className="close-button-modal1Modalo" onClick={onClose}>
        <i className="fa-regular fa-circle-xmark"></i>
      </button>
      <div className="modal-container-welcomeModalo">
        <video autoPlay loop muted className="background-videoModalo">
          <source src={`${linkUrl}/wp-content/uploads/2024/09/background_signup_peaxel.mp4`} type="video/mp4" />
        </video>
        <div className="welco1Modalo">
          <div className="welco12Modalo">
            <div className="modalo-title">{t('createYourLegacy')}</div> {/* Main title */}
            <form className="welcome-formModalo">
              <div className="form-sectionmodalo">
                <div className="form-subtitlemodalo">{t('chooseYourManagerName')}</div> {/* Subtitle for username */}
                <input
                  type="text"
                  placeholder={t('enterYourManagerName')}
                  value={username}
                  onChange={handleUsernameChange}
                  required
                />
                {!isUsernameAvailable && <span className="input-login-info-avModalo">•&nbsp;{t('usernameNotAvailable')}</span>}
              </div>
              <div className="form-sectionmodalo">
                <div className="form-subtitlemodalo">{t('chooseYourTeamName')}</div> {/* Subtitle for team name */}
                <input
                  type="text"
                  placeholder={t('enterYourTeamName')}
                  value={teamName}
                  onChange={handleTeamNameChange}
                  required
                />
                {!isTeamNameAvailable && <span className="input-login-info-avModalo">•&nbsp;{t('teamNameNotAvailable')}</span>}
              </div>
              <div className="terms-checkboxModalo">
                <input
                  type="checkbox"
                  checked={termsAccepted}
                  onChange={handleTermsChange}
                  required
                />
                <label>
                  <a href={`${linkUrl}/peaxel-terms-and-conditions/`} target="_blank" rel="noopener noreferrer">
                    {t('iAcceptTheTermsAndConditions')}
                  </a>
                </label>
              </div>
              <div className="terms-checkboxModalo">
                <input
                  type="checkbox"
                  checked={isAgeCertified}
                  onChange={handleAgeCertificationChange}
                  required
                />
                <label style={{ fontSize: '70%' }}>
                  {t('iAmAtLeast18YearsOld')}
                </label>
              </div>
              <button
                type="button"
                onClick={handleSignUp}
                disabled={!isUsernameAvailable || !isTeamNameAvailable || !termsAccepted || !isAgeCertified || !teamName}
              >
                {t('register')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModalo;
