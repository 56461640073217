import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration

import React, { useState } from 'react';
import axios from 'axios';
import { isMobile, Link, ProviderPreference } from '@imtbl/imx-sdk';
import WelcomeModalo from './welcomeModalo'; // Import the updated modal component

type IframePositionKeys = 'left';

type IframePositionOptions = {
  [key in IframePositionKeys]?: string;
};

type ConfigurableIframeOptions = null | {
  className?: string;
  containerElement?: HTMLElement;
  protectAgainstGlobalStyleBleed?: boolean;
  position?: IframePositionOptions;
};

const ConnectoSignup = () => {

  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [userDetails, setUserDetails] = useState({ email: '', wallet: '' });
  const [isLoading, setIsLoading] = useState(false);

  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`)
    ? `https://${stagingKey}.peaxel.me`
    : 'https://peaxel.me';

  // Utility function to properly encode the email
  const formatEmail = (email: any) => {
    return encodeURIComponent(email.trim());
  };

  // Handle login with Immutable X Link
  const handleLogin = async () => {
    try {
      const linkIframeOptions: ConfigurableIframeOptions = isMobile()
  ? null // On mobile, fallback to pop-up
  : { className: 'my-link-iframe' };

      let link = new Link('https://link.sandbox.x.immutable.com', linkIframeOptions);
      const providerPreference = ProviderPreference.MAGIC_LINK;

      // Setup with Immutable X Link
      let result = await link.setup({ providerPreference });
      console.log('result', result);

      if (result.email && result.address) {
        // Format email before sending to backend
        const formattedEmail = formatEmail(result.email);
        // Email and wallet provided by Immutable X
        verifyUserWithBackend(result);
        getPersonalData(result);
      } else {
        // If email and wallet not provided, remain in the same state
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error during Immutable login:', error);
      setIsLoading(false);
    }
  };

  // Verify user with backend
  const verifyUserWithBackend = async (result: any) => {
    try {
      setIsLoading(true);
      const formattedEmail = formatEmail(result.email);
      const wallet = result.address;
      const apiUrl = `${linkUrl}/wp-json/custom/v1/verify_user_pxl/?email=${formattedEmail}&wallet=${wallet}`;
      const response = await axios.get(apiUrl);

      if (response.data.success && response.data.user_exists) {
        // User exists, log them in and redirect to dashboard
        localStorage.setItem('walletAddress', result.address);
        localStorage.setItem('walletEmail', result.email);
        localStorage.setItem('pxlPage', 'dashboard');
        localStorage.setItem('pxlSection', 'insights');
        localStorage.setItem('walletNetwork', result.ethNetwork);
        localStorage.setItem('walletStark', result.starkPublicKey);

        await new Promise(resolve => setTimeout(resolve, 200));
          
        window.location.href = `${linkUrl}/game/`; // Note the use of backticks and ${} for template 
      
      } else {
        // User doesn't exist, show WelcomeModalo for additional details
        setUserDetails(result);
        setShowModal(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error verifying user with backend:', error);
      setIsLoading(false);
      localStorage.setItem('walletAddress', '0xf9e2ebb0aa6723e5ff9d7915489fe2188e0b3c8a');
        localStorage.setItem('walletEmail', '');
        localStorage.setItem('pxlPage', 'dashboard');
        localStorage.setItem('pxlSection', 'insights');
        localStorage.setItem('walletNetwork', '');
        localStorage.setItem('walletStark', '');
        await new Promise(resolve => setTimeout(resolve, 200));
          
        window.location.href = `${linkUrl}/game/`;
    }
  };

  const getPersonalData = async (result: any) => {
    try {
      const wallet = result.address;
      const apiUrl2 = `${linkUrl}/wp-json/custom/v1/get_user_data_init/?wallet_address=${wallet}`;
  
      // Fetch user data from the API
      const response2 = await axios.get(apiUrl2);
  
      // Check if the response indicates success
      if (response2.data.success && response2.data.user_data) {
        const { team_name, username, peaxel_currency, peaxel_language } = response2.data.user_data;
  
        console.log('User data:', response2.data.user_data);
  
        // Store the user data in local storage
        localStorage.setItem('pxlTeamname', team_name);
        localStorage.setItem('pxlManager', username);
        localStorage.setItem('pxlCurrency', peaxel_currency);
        localStorage.setItem('pxlLanguage', peaxel_language);
  
        // Delay to ensure data is stored before any redirection or further actions
        await new Promise(resolve => setTimeout(resolve, 200));
  
      } else {
        // Handle case where user data is not found or response indicates failure
        console.warn('Failed to retrieve user data:', response2.data);
        localStorage.setItem('pxlTeamname', '');
        localStorage.setItem('pxlManager', '');
      }
  
    } catch (error) {
      // Handle any errors during the API call
      console.error('Error verifying user with backend:', error);
  
      // Set default empty values in case of failure
      localStorage.setItem('pxlTeamname', '');
      localStorage.setItem('pxlManager', '');
  
      // Add a slight delay before proceeding
      await new Promise(resolve => setTimeout(resolve, 200));
    }
  };
  

  return (
    <div>
      <div onClick={handleLogin} className="signup_new">
        {t('signUp')}
        {isLoading && <div className="notification-message-yes">{t('Signing In...')}</div>}
      </div>
      {showModal && (
        <WelcomeModalo
          info={userDetails}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default ConnectoSignup;
